<template>
  <div>
    <b-form-group label="Nome:">
      <b-form-input
        v-model="role.name"
        placeholder="Nome"
        :state="validateState('name')"
      ></b-form-input>
      <b-form-invalid-feedback
        >Nome é um campo obrigatório.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Permissões:">
      <b-form-checkbox-group
        v-for="(permission, key) in permissions"
        :key="key"
        v-model="role.permission"
      >
        <b-form-checkbox :value="permission.id">{{
          permission.label
        }}</b-form-checkbox>
      </b-form-checkbox-group>

      <b-form-invalid-feedback :state="validateState('permission')">
        <span v-if="!$v.role.permission.required"
          >Permissão é um campo obrigatório.</span
        >
        <span v-if="!$v.role.permission.minLength"
          >Selecione pelo menos {{$v.role.permission.$params.minLength.min}} permissões.</span
        >
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  mixins: [validationMixin],
  data: () => ({}),
  validations: {
    role: {
      name: {
        required,
        minLength: minLength(3)
      },
      permission: {
        required,
        minLength: minLength(3)
      }
    }
  },
  computed: {
    ...mapState("acl", ["role", "permissions"])
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.role[name];
      return $dirty ? !$error : null;
    },
    isValid() {
      this.$v.role.$touch();
      return !this.$v.role.$anyError;
    }
  },
  created() {}
};
</script>
